import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import {withI18n} from "@lingui/react";
import {getLocaleFromPath} from "utils/i18n.js";
import Layout from "components/layout/layout";
import { graphql } from "gatsby"
import 'animate.css';



const IndexPage = ({location, data}) => {

    const locale = getLocaleFromPath(location.pathname);
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    let photos = data.allFile.edges.map(({ node }, index) => (

            {
              src: node.childImageSharp.fluid.srcWebp,
              width: node.childImageSharp.original.width,
              height: node.childImageSharp.original.height
            }
    ));
    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
      }, []);
    
      const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
      };

    return (
        <Layout location={location}>
            <div className="MainContent">
                <section id="contact">
                <div className="container">
                        <h2>Galéria</h2>
                        <div className="c100p">
                        <div>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
                        </div>
                        <div className="cleaner">&nbsp;</div>
                    </div>

                </section>
            </div>
        </Layout>
    );
};

export default withI18n()(IndexPage);



export const pageQuery = graphql`
  query {
    allFile(filter: {relativeDirectory: {eq: "galeria"}}) {
        edges {
          node {
            dir
            relativeDirectory
            relativePath
            publicURL
            childImageSharp {
              fluid(quality: 70) {
                srcWebp
              }
              original {
                width
                height
              }
            }
          }
        }
      }
  }
`;